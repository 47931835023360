<template>
  <b-row class="mt-2 mb-3">
    <b-col cols="12" md="3">
      <v-text-field
        v-model="form.start"
        label="Start Date"
        type="date"
        outlined
        dense
        class="w-100"
      ></v-text-field>
    </b-col>
    <b-col cols="12" md="3">
      <v-text-field
        v-model="form.end"
        label="End Date"
        type="date"
        outlined
        dense
        class="w-100"
      ></v-text-field>
    </b-col>
    <b-col cols="12" md="2">
      <v-select
        v-model="form.paymentMethod"
        :items="paymentMethods"
        label="Payment Method"
        dense
        outlined
        class="w-100"
      ></v-select>
    </b-col>
    <b-col cols="12" md="2">
      <v-select
        v-model="form.status"
        :items="statuses"
        label="Order Status"
        dense
        outlined
        class="w-100"
      ></v-select>
    </b-col>
    <b-col cols="12" md="2" class="text-right">
      <v-btn
        :loading="loading"
        class="font-weight-bolder bg-primary white--text tex-white font-size-sm rounded-lg"
        @click="fetch"
        style="color:white"
      >
        Lookup
      </v-btn>
      <v-btn
        class="font-weight-bolder ml-1 bg-danger white--text font-size-sm rounded-lg"
        @click="clearFilters"
        :loading="clearfiltersloading"
        style="color:white"
      >
        Clear
      </v-btn>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "SearchWidget",
  data() {
    return {
      form: {
        start: null,
        end: null,
        paymentMethod: null,
        status: null
      },
      loading: false,
      clearfiltersloading:false,
      paymentMethods: [
        { text: "Online Payment", value: "card" },
        { text: "Cash on Delivery (COD)", value: "cod" }
      ],
      statuses: [
        "Cancelled",
        "Acknowledged",
        "Order Prepared",
        "Delivered",
        "Pending",
        "Collected",
        "Order on Way"
      ]
    };
  },
  computed: {
  isFilterApplied() {
    return (
      this.form.start !== null ||
      this.form.end !== null ||
      this.form.paymentMethod !== null ||
      this.form.status !== null
    );
  }
},
  methods: {
    async clearFilters() {
  this.clearfiltersloading=true
  this.form.start = null;
  this.form.end = null;
  this.form.paymentMethod = null;
  this.form.status = null;
      try {
        // Simulate a network request
        await new Promise((resolve) => setTimeout(resolve, 2000));
        this.$emit("searchOrder", {});
        this.clearfiltersloading=false

      } catch (error) {
        console.error("Error fetching data:", error);
      }
},

async fetch() {
  this.loading = true;
  try {
    // Simulate a network request
    await new Promise((resolve) => setTimeout(resolve, 2000));

    // Create the payload object
    const payload = {

    };
    if(this.form.start!==null && this.form.end!==null ){
    payload.start = this.form.start;
    payload.end = this.form.end;

  }

    // Add optional keys only if they are not null
    if (this.form.paymentMethod !== null) {
      payload.paymentMethod = this.form.paymentMethod;
    }
    if (this.form.status !== null) {
      payload.status = this.form.status;
    }

    // Emit the event with the filtered payload
    this.$emit("searchOrder", payload);
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    this.loading = false;
  }
}
  }
};

</script>