<template>
  <div >
    <SearchWidget @searchOrder="getFetchOrders" />

    <template v-if="getLoading">
      <b-row class="justify-content-center my-5">
      <b-col cols="auto">
        <b-spinner variant="primary" label="Loading..."></b-spinner>
      </b-col>
      </b-row>
      </template>
      <template>
        <b-row v-if="form.orders != '' && getLoading==false">
      <b-col>
        <TotalOrdersWidget :orders="form.orders"></TotalOrdersWidget>
      </b-col>
      <b-col>
        <CancelledOrdersWidget  :orders="form.orders"></CancelledOrdersWidget>
      </b-col>
      <b-col>
        <TodayOrdersCountWidget :orders="form.orders"></TodayOrdersCountWidget>

      </b-col>
   
      <b-col></b-col>
    </b-row>
    <b-row v-if="form.orders != '' ">
      <b-col>
        <OrdersTableWidget
          :orders="form.orders"
          :title="'Orders History'"
          @update="updateOrders"

        ></OrdersTableWidget>
      </b-col>
    </b-row>
    <div v-if="getLoading==false && form.orders.length==0">
      <center>
        <h2 class="font-poppins">No Orders found</h2>
      </center>
    </div>
      </template>

  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TotalOrdersWidget from "@/view/pages/orders/components/TotalOrdersWidget.vue";
import SearchWidget from "@/view/pages/orders/components/SearchWidget.vue";
import OrdersTableWidget from "@/view/pages/orders/components/OrdersTableWidget.vue";
import { ORDERS } from "@/core/services/store/sales.module";
import { mapGetters } from "vuex";
import TodayOrdersCountWidget from "./components/TodayOrdersCountWidget.vue";
import CancelledOrdersWidget from "./components/CancelledOrdersWidget.vue";
export default {
  name: "TodayOrders",
  computed: {
    ...mapGetters(["currentUser","getLoading"]),
  },
  components: {
    TotalOrdersWidget,
    SearchWidget,
    OrdersTableWidget,
    TodayOrdersCountWidget,
    CancelledOrdersWidget
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Orders" },
      { title: "Orders History" },
    ]);

    // look for current user
    if (this.currentUser.token !== "") {
      // call method to get orders
      this.fetchOrders({});
    }
  },

  data() {
    return {
      form: {
        orders: "",
      },
    };
  },
  methods: {
    getFetchOrders(obj){
   
        this.fetchOrders(obj)
      
    },
    updateOrders() {
      this.fetchOrders();
    },
    fetchOrders(obj) {
      this.$store
        .dispatch(ORDERS, obj)
        // go to which page after successfully login
        .then((resp) => {
          this.form.orders = resp.data;
          console.log(resp.data);
          // this.$router.push({ name: "dashboard" })
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
